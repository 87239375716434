import axios from 'axios'

const client = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL, withCredentials: true })

export const request = ({ ...options }) => {
    // const userToken = localStorage.getItem('myToken')
    // client.defaults.withCredentials = true

    client.defaults.headers.common = {
        'Access-Control-Allow-Origin': '*'
    }
    const onSuccess = (response) => {
        return response.data
    }

    const onError = (error) => {
        throw error
    }

    return client(options).then(onSuccess).catch(onError)
}
