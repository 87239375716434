import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Result } from 'antd'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error
        }
    }

    componentDidCatch(error, info) {
        console.log(error)
        console.log(info)
    }
    render() {
        if (this.state.hasError) {
            return <Result status="500" title="Something went wrong please try again later." />
        }
        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
}
