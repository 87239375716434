/* eslint-disable no-undef */
import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseconfig'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

let messaging

export const fetchToken = (setTokenData) => {
    return getToken(messaging, {
        vapidKey: 'BGLqCH_qrJqJ9r_x0wq3V-VcHt5yxc689iGPeohj7ryfDZUZ0eV8YMqvbHx7WlNNtb2ARmupQjT4W3i3T6sJzKY'
    })
        .then((currentToken) => {
            // console.log(currentToken)
            if (currentToken) {
                setTokenData({ tokenFound: true, token: currentToken })
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                setTokenData({ tokenFound: false, token: null })
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // catch error while creating client token
        })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })

// if (firebase) console.log(firebase.apps)

if (!firebase.apps.length) {
    const firebaseApp = initializeApp(firebaseConfig)
    messaging = getMessaging(firebaseApp)
    // console.log(firebase.apps.length)
} else {
    firebase.app()
}
