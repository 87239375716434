import { executiveCommets, pickup, PICKUP_CANCEL_REASONS, timeSlots } from '../../../utils/eps'
import { request } from '../../../utils/axios'

export const getPickTimeSlots = (date, coordinates) => {
    return request({ url: pickup.slotsList(date, 'order', coordinates), method: 'get' })
}
export const getPickTimeSlotsWithoutCoordinates = (date) => {
    return request({ url: timeSlots.slotListWithoutCoordinates(date), method: 'get' })
}
export const getPickTimeSlotsWithoutDate = () => {
    return request({ url: timeSlots.activeSlotList(), method: 'get' })
}

export const disableTimeSlot = (values) => {
    return request({ url: pickup.timeSlotStatus, method: 'patch', data: { ...values } })
}

export const getPickOrders = (
    page,
    limit,
    date,
    id,
    status,
    search = undefined,
    rateFilter = undefined,
    pickupPaymentFilter = undefined,
    B2CPickupFilterExe = undefined,
    groupId = undefined
) => {
    return request({
        url: pickup.pickUpOrders(
            page,
            limit,
            date,
            id,
            status,
            search,
            rateFilter,
            pickupPaymentFilter,
            B2CPickupFilterExe,
            groupId
        ),
        method: 'get'
    })
}

export const getOrderDetails = (id) => {
    return request({ url: pickup.orderDetails(id), method: 'get' })
}

export const getPickUpExec = (values) => {
    return request({ url: pickup.pickUpExecutiveForEcoStoreAndPickUps(values), method: 'get' })
}

export const getDriversAPI = () => {
    return request({ url: pickup.EP_DRIVERS_LIST, method: 'get' })
}

export const assignPickExecAPIHelper = (values) => {
    return request({ url: pickup.assignPickExecEP(values.id), method: 'put', data: { ...values } })
}
export const closeOrderAPIHelper = (values) => {
    return request({ url: pickup.closeOrderEP(values.id), method: 'put', data: { ...values } })
}

export const getCancellationReason = () => {
    return request({ url: PICKUP_CANCEL_REASONS.EP_GET_REASONS('admin'), method: 'get' })
}

export const cancelOrderByAdmin = (values) => {
    return request({ url: pickup.cancelOrderEP(values.id), method: 'put', data: { ...values.data } })
}

export const getOrderMaterialsDetailsApi = (id) => {
    return request({ url: pickup.epOrderMaterialsDetails(id), method: 'get' })
}

export const reopenOrderApi = (values) => {
    return request({ url: pickup.reopenOrder(values.id), method: 'put', data: { ...values } })
}
export const checkOngoingStatus = (id) => {
    return request({ url: pickup.ongoingOrders(id), method: 'get' })
}

export const getSingleOrderComments = (id) => {
    return request({ url: executiveCommets.GET_SINGLE_ORDER_COMMENT_SUMMARY(id), method: 'get' })
}

export const duplicateOrderPost = (values) => {
    return request({ url: pickup.duplicateOrder(values.id), method: 'post', data: {} })
}
