import React from 'react'
import SignInStyle from '../style/signin.module.scss'
import { Form, Button, Row, Col, Input } from 'antd'
import roundImage1 from '../../../assets/signin/Ellipse95.png'
import roundImage2 from '../../../assets/signin/Ellipse94.png'
import roundImage3 from '../../../assets/signin/Ellipse98.png'
import roundImage4 from '../../../assets/signin/Ellipse96.png'
import roundImage2Inner from '../../../assets/signin/Ellipse97.png'
import logoImage from '../../../assets/signin/EcocrewLogo@3x3.png'
import logoName from '../../../assets/signin/ecocrew.png'
import logoDesc from '../../../assets/signin/rewarding.png'
import { DownCircleOutlined } from '@ant-design/icons'
function ForgotPassword() {
    const scrollTo = () => {
        window.scrollTo({
            top: 900,
            behavior: 'smooth'
        })
    }
    return (
        <>
            <div className={SignInStyle.mainContainer}>
                <Row className={SignInStyle.content}>
                    <Col className={SignInStyle.formContent} lg={12} md={24}>
                        <div className={SignInStyle.SignInForm}>
                            <h1 className={SignInStyle.forgotText}>Forgot password ?</h1>
                            <p className={SignInStyle.forgotPara}>
                                Enter your email address and we will send you a password reset link.
                            </p>
                            <Form
                                className={SignInStyle.form}
                                name="forgot password form"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                autoComplete="off">
                                <Form.Item
                                    label="Please enter your email address"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Please enter your email address!' },
                                        {
                                            pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
                                            message: 'please enter valid email address'
                                        }
                                    ]}>
                                    <Input className={SignInStyle.inputContainer} />
                                </Form.Item>
                                <br />

                                <Form.Item>
                                    <Button type="default" htmlType="submit" className={SignInStyle.submitBtn}>
                                        Continue
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col className={SignInStyle.mainPageChild2} lg={12} md={24}>
                        <div className={SignInStyle.imageContent}>
                            <div className={SignInStyle.image1}>
                                <img src={roundImage1} alt="image1" />
                            </div>
                            <div className={SignInStyle.image2}>
                                <img className={SignInStyle.image2Inner} src={roundImage2Inner} alt="image2inner" />
                                <img src={roundImage2} alt="image2" />
                            </div>
                            <div className={SignInStyle.logoContainer}>
                                <div className={SignInStyle.logoImage}>
                                    <img className={SignInStyle.imgLogo} src={logoImage} alt="logo" />
                                    <img className={SignInStyle.logoName} src={logoName} alt="logoName" />
                                    <img className={SignInStyle.logoDesc} src={logoDesc} alt="logoDesc" />
                                </div>
                            </div>
                            <div className={SignInStyle.image3}>
                                <img src={roundImage3} alt="" />
                            </div>
                            <div className={SignInStyle.image4}>
                                <img className={SignInStyle.image4Inner} src={roundImage2Inner} alt="image4inner" />
                                <img src={roundImage4} alt="" />
                            </div>
                            <div className={SignInStyle.angleCircle}>
                                <div className={SignInStyle.circle}>
                                    <Button onClick={() => scrollTo()} type="link" className={SignInStyle.btn}>
                                        <DownCircleOutlined />
                                    </Button>
                                </div>
                                <div className={SignInStyle.text}>
                                    <h4>Scroll to bottom</h4>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ForgotPassword
