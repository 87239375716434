/* eslint-disable no-unused-vars */
import React, { lazy, useContext, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { MainContext } from '../App.js'

const Layout = lazy(() => import('../components/Layout'))

const Dashboard = lazy(() => import('../components/cmpDashboard'))
const DasboardMap = lazy(() => import('../components/cmpDashboard/comps/map'))
const PolygonMap = lazy(() => import('../components/cmpPolygonMap/index.jsx'))
const DasboardServiceMap = lazy(() => import('../components/cmpDashboard/comps/map/servicemap'))

const ListAllUsers = lazy(() => import('../components/cmpUser'))
const Referrals = lazy(() => import('../components/cmpReferrals/Referrals'))
const Executives = lazy(() => import('../components/cmpPickupExecutives/components/Executives.jsx'))
const AddPickExec = lazy(() => import('../components/cmpPickupExecutives/components/comp/AddPickExec.jsx'))
const MoreDetails = lazy(() => import('../components/cmpPickupExecutives/components/MoreDetails'))
const EcoStore = lazy(() => import('../components/cmpEcoStore'))
const EcoStoreProductsBrand = lazy(() => import('../components/cmpEcoStore/components/brandComps/BrandInnerComp'))
const PickupReasons = lazy(() => import('../components/cmpCancellReason/components/PickupReasons'))
const StoreReasons = lazy(() => import('../components/cmpCancellReason/components/StoreReasons'))

const EcoStoreAddBrand = lazy(() => import('../components/cmpEcoStore/components/brandComps/comp/AddBrand'))
const RateCard = lazy(() => import('../components/cmpRateCard/components/RateCard'))
const RateCardProduct = lazy(() => import('../components/cmpRateCard/components/RateCardProduct'))
const RateCardSubProducts = lazy(() => import('../components/cmpRateCard/components/comp/SubProduct'))

const RateCardB2B = lazy(() => import('../components/cmpRateCardB2B/components/RateCard.jsx'))
const RateCardProductB2B = lazy(() => import('../components/cmpRateCardB2B/components/RateCardProduct'))
const RateCardSubProductsB2B = lazy(() => import('../components/cmpRateCardB2B/components/comp/SubProduct'))

const Slots = lazy(() => import('../components/cmpSlots'))
const PytModes = lazy(() => import('../components/cmpPaymentsMode'))
const ViewUserDetails = lazy(() => import('../components/cmpUser/components/Profile'))
const PickupsSchedule = lazy(() => import('../components/cmpPickups/components/PickupsSchedule'))
const SinglePickupOCC = lazy(() => import('../components/cmpPickups/components/OCC/OCC'))

const ActiveB2B = lazy(() => import('../components/cmpB2B/components/activeBusiness'))
const PendingB2B = lazy(() => import('../components/cmpB2B/components/pendingBusiness'))
const RejectedB2B = lazy(() => import('../components/cmpB2B/components/rejectBusiness'))
const BProfleB2B = lazy(() => import('../components/cmpB2B/components/BProfleB2B.jsx'))
const PendingMapMarkers = lazy(() => import('../components/cmpB2B/components/pendingBusiness/ViewInMap'))
const B2BPickupsSchedule = lazy(() => import('../components/cmpB2BPOrders/components/PickupsSchedule'))
const B2BOCC = lazy(() => import('../components/cmpB2BPOrders/components/OCC/OCC'))

const Feeds = lazy(() => import('../components/cmpEcoFeeds'))
const Posts = lazy(() => import('../components/cmpEcoFeeds/comps/InnerComps/Posts'))
const EcoStoreOrders = lazy(() => import('../components/cmpEcoOrders/components/EcoStoreOrder'))
const EcoStoreOrderOcc = lazy(() => import('../components/cmpEcoOrders/components/OCC/Occ'))
const Impact = lazy(() => import('../components/cmpEcoImpacts'))

const UserQuery = lazy(() => import('../components/cmpCustomerSupport/UserQueries/Index.jsx'))
const BusinessQuery = lazy(() => import('../components/cmpCustomerSupport/BusinessQueries/Index.jsx'))
const PickupQuery = lazy(() => import('../components/cmpCustomerSupport/PickupExecetiveQueries/Index.jsx'))
const WarehouseManagerQuery = lazy(() => import('../components/cmpCustomerSupport/WarhouseManager/Index'))
const DriverQuery = lazy(() => import('../components/cmpCustomerSupport/DriverQueries/Index.jsx'))

const DriverList = lazy(() => import('../components/cmpDriver'))
const DriverAddEdit = lazy(() => import('../components/cmpDriver/comp/DriverInnerComps/AddEditDriver/AddEditDriver'))
const DriverLoads = lazy(() => import('../components/cmpDriver/comp/DriverInnerComps/DriverLoads/DriverLoads'))
const DriverOCC = lazy(() => import('../components/cmpDriver/comp/DriverInnerComps/OCC/DriverOCC'))
const Warhouse = lazy(() => import('../components/cmpWarehouse'))
const CheckAccess = lazy(() => import('../components/cmpNoAccessRoutes'))
const NotAccess = lazy(() => import('../components/cmpNoAccessRoutes/NoaccessRoute.jsx'))

const SingleWareHouseDetails = lazy(() => import('../components/cmpWarehouse/comps/singleWarehouseDetails'))
const CreateOutward = lazy(() =>
    import('../components/cmpWarehouse/comps/singleWarehouseDetails/inOrOutwardDetails/CreateOutward')
)

const SingleWarehouseInner = lazy(() =>
    import('../components/cmpWarehouse/comps/singleWarehouseDetails/inOrOutwardDetails')
)
const SingleOutwardDetails = lazy(() =>
    import('../components/cmpWarehouse/comps/singleWarehouseDetails/inOrOutwardDetails/OutwardMainDetails')
)
const WarhouseAddEdit = lazy(() =>
    import('../components/cmpWarehouse/comps/AddEditWarehouseAndManager/Warehouse/AddEditWarehouse')
)
const ManagerAddEdit = lazy(() =>
    import('../components/cmpWarehouse/comps/AddEditWarehouseAndManager/Manager/AddEditManager')
)
const AdminUsers = lazy(() => import('../components/cmpAdminUsers'))
const EcoCoins = lazy(() => import('../components/cmpEcoCoins'))
const OutwardClient = lazy(() => import('../components/cmpOutwardClient'))
const ExecutiveCommets = lazy(() => import('../components/cmpExeecutiveComments'))
const PickupExpenses = lazy(() => import('../components/cmpPickupExpenses/components/PExpenses'))
const SinglePickupExpense = lazy(() => import('../components/cmpPickupExpenses/components/MoreDetails'))
const NGOList = lazy(() => import('../components/cmpNGO'))
const CreateNGO = lazy(() => import('../components/cmpNGO/components/CreateNGO'))
const SingleNGO = lazy(() => import('../components/cmpNGO/components/SingleDetails'))
const ViewNgoPickups = lazy(() => import('../components/cmpNGO/components/ViewPickups'))
const NGOPayments = lazy(() => import('../components/cmpNGO/components/comp/NGOPayments'))
const AdminExpenses = lazy(() => import('../components/cmpAdminExpenses/index'))
const PinCodes = lazy(() => import('../components/cmpPinCodes'))
const PincodeLayereMap = lazy(() => import('../components/cmpPinCodes/dataDrivenLayer'))
// const SingleAdminExp = lazy(() => import('../components/cmpAdminExpenses/comp/SingleAdminExp'))

const Map = lazy(() => import('../components/cmpMap'))
const FestiveOffers = lazy(() => import('../components/cmpFestiveOffers'))

//Suspense wrapper
import SuspenseWrapper from './SuspenseWrapper'
import { routes } from './routes'
import { getFilteredRoutes } from './constant.js'

function resolveComponent(route) {
    switch (route.name) {
        case 'Dashboard':
            return <Dashboard />

        case 'All details':
            return <DasboardServiceMap />

        case 'Dashboard Service Map':
            return <DasboardServiceMap />

        case 'Dashboard Map':
            return <DasboardMap />

        case 'Admin users':
            return <AdminUsers />

        case 'User list':
            return <ListAllUsers />

        case 'View user list':
            return <ViewUserDetails />

        case 'View business':
            return <BProfleB2B />

        case 'Active businesses':
            return <ActiveB2B />

        case 'Pending businesses':
            return <PendingB2B />

        case 'Rejected businesses':
            return <RejectedB2B />

        case 'Pending map view':
            return <PendingMapMarkers />

        case 'Slots':
            return <Slots />

        case 'Feeds':
            return <Feeds />

        case 'Feed post':
            return <Posts />

        case 'Impacts':
            return <Impact />

        case 'OCC Info Details':
            return <SinglePickupOCC />

        case 'Pickup ongoing':
            return <PickupsSchedule />

        case 'Pickup completed':
            return <PickupsSchedule />

        case 'Pickup cancelled':
            return <PickupsSchedule />

        case 'Business Order Details':
            return <B2BOCC />

        case 'B2B ongoing':
            return <B2BPickupsSchedule />

        case 'B2B completed':
            return <B2BPickupsSchedule />

        case 'B2B cancelled':
            return <B2BPickupsSchedule />

        case 'Eco Store Orders Details':
            return <EcoStoreOrderOcc />

        case 'Current orders':
            return <EcoStoreOrders />

        case 'Ordered by Vendors':
            return <EcoStoreOrders />

        case 'Order in warehouse':
            return <EcoStoreOrders />

        case 'Assigned to delivery':
            return <EcoStoreOrders />

        case 'Out for delivery':
            return <EcoStoreOrders />

        case 'Completed orders':
            return <EcoStoreOrders />

        case 'Cancelled orders':
            return <EcoStoreOrders />

        case 'Replacement requested':
            return <EcoStoreOrders />

        case 'Replacement completed':
            return <EcoStoreOrders />

        case 'Customer issues users':
            return <UserQuery />

        case 'Customer issues business':
            return <BusinessQuery />

        case 'Customer issues Pickups / Delivery agents':
            return <PickupQuery />

        case 'Customer issues warehouse manager':
            return <WarehouseManagerQuery />

        case 'Customer issues driver':
            return <DriverQuery />

        case 'Cancel Pickup orders':
            return <PickupReasons />

        case 'Cancel eco store':
            return <StoreReasons />

        case 'Pickup executive':
            return <Executives />

        case 'View Single Executive Details':
            return <MoreDetails />

        case 'Edit Single Executive Details':
            return <AddPickExec />

        case 'Add Executive Details':
            return <AddPickExec />

        case 'Driver':
            return <DriverList />

        case 'View Single Driver Details':
            return <DriverLoads />

        case 'View Executive Order History':
            return <DriverOCC />

        case 'Add Driver Details':
            return <DriverAddEdit />

        case 'Edit Driver Details':
            return <DriverAddEdit />

        case 'Add Warehouse':
            return <WarhouseAddEdit />

        case 'View WareHouse Details':
            return <SingleWareHouseDetails />

        case 'Warehouse Material Details':
            return <SingleWarehouseInner />

        case 'Edit Warehouse Details':
            return <WarhouseAddEdit />

        case 'Create outward':
            return <CreateOutward />

        case 'Driver recieved materials':
            return <SingleOutwardDetails />

        case 'Edit Manager Details':
            return <ManagerAddEdit />

        case 'Add Manager Details':
            return <ManagerAddEdit />

        case 'View eco store brand':
            return <EcoStoreProductsBrand />

        case 'Add eco store brand':
            return <EcoStoreAddBrand />

        case 'Edit eco store brand':
            return <EcoStoreAddBrand />

        case 'Rate card':
            return <RateCard />

        case 'View rate card material':
            return <RateCardProduct />

        case 'View rate card sub products':
            return <RateCardSubProducts />

        case 'B2B rate card':
            return <RateCardB2B />

        case 'View b2b rate card material':
            return <RateCardProductB2B />

        case 'View b2b rate card sub products':
            return <RateCardSubProductsB2B />

        case 'Payment modes':
            return <PytModes />

        case 'NGOs':
            return <NGOList />

        case 'View NGO':
            return <SingleNGO />

        case 'Update NGO':
            return <CreateNGO />

        case 'Create NGO':
            return <CreateNGO />

        case 'Configuration':
            return <EcoCoins />

        case 'Outward client':
            return <OutwardClient />

        case 'Groups / Pincodes':
            return <PinCodes />

        case 'Pincodes available':
            return <PincodeLayereMap />

        case 'Polygon available':
            return <PolygonMap />

        case 'Festive offers':
            return <FestiveOffers />

        case 'Pickup expenses':
            return <PickupExpenses />

        case 'View pickup expenses':
            return <SinglePickupExpense />

        case 'Admin expenses':
            return <AdminExpenses />

        case 'NGO payments':
            return <NGOPayments />

        case 'View NGO pickup pending':
            return <ViewNgoPickups />

        case 'View NGO pickup complete':
            return <ViewNgoPickups />

        case 'Business order details':
            return <B2BOCC />

        case 'Referrals':
            return <Referrals />

        case 'Warehouse manager':
            return <Warhouse />

        case 'Eco store':
            return <EcoStore />
        case 'Executive comments':
            return <ExecutiveCommets />
        default:
            return <NotAccess />
    }
}

function transformRouteToJSX(route) {
    const Component = resolveComponent(route)
    const fullPath = route.path

    return (
        <React.Fragment key={route.key ? route.key : fullPath}>
            {fullPath !== '/' || fullPath !== '' ? (
                <Route path={fullPath} element={<SuspenseWrapper component={Component} />} />
            ) : null}
            {route.children && Object.values(route.children).map((child) => transformRouteToJSX(child, fullPath))}
            {route.innerRoutes && Object.values(route.innerRoutes).map((child) => transformRouteToJSX(child, fullPath))}
        </React.Fragment>
    )
}

function ProtectedRoutes() {
    const { pathname } = useLocation()
    const { rolesArray } = useContext(MainContext)
    const routeNamesArray = rolesArray
    const filteredRoutes = rolesArray && rolesArray.length ? getFilteredRoutes(routeNamesArray, routes) : {}
    const transformedRoutes = Object.values(filteredRoutes).map((route) => transformRouteToJSX(route))

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/check-access" />} />
            <Route path="/" element={<SuspenseWrapper component={<Layout />} />}>
                {transformedRoutes}
                <Route
                    path="/warhouse-and-manager/warhouse-details/:warehouseId"
                    element={<SuspenseWrapper component={<SingleWareHouseDetails />} />}
                />
                <Route path="/no-access" element={<SuspenseWrapper component={<NotAccess />} />} />
            </Route>
            <Route path="/map-view" element={<SuspenseWrapper component={<Map />} />} />
            <Route path="/polygon-map-view" element={<SuspenseWrapper component={<PolygonMap />} />} />
            <Route path="/check-access" element={<SuspenseWrapper component={<CheckAccess />} />} />
            <Route path="*" element={<SuspenseWrapper component={<NotAccess />} />} />
        </Routes>
    )
}
export default ProtectedRoutes
