import React from 'react'
import SignIn from './components/SignIn'

function Auth() {
    return (
        <div>
            <SignIn />
        </div>
    )
}

export default Auth
