export const auth = Object.freeze({
    signIn: 'admin-auth/login'
})

export const users = Object.freeze({
    fetchUser: (limit, page, search = undefined, filter = undefined) => {
        let url = `user?limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    //Users Endpoints
    epUserKPI: 'user/kpi',
    epUpdateUserStatus: (id) => `user/${id}`,
    epGetUserInfo: (id) => `user/${id}`,
    epGetPickOrders: (id, page, limit, search = undefined) => {
        let url = `user/pickUp/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    epreferralwithdrawal: (id) => `user-referral-system/request-withdrawal/user-id/${id}?completed=true`,
    //Eco Store End points
    // {{base_url}}/ecostore/admin/user/7e6a4e73-49c9-4f3b-83d7-c3be8cdb6a29?status=executive_assigned&page=3&limit=10
    epGetStoreOrder: (id, status = undefined, page, limit, search = undefined) => {
        let url = `ecostore/admin/user/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        } else if (status) {
            url += `&status=${status}`
        }
        return url
    },
    epEcoStoreOrderDetailsById: (order) => `ecostore/orders/${order}`,
    epSavedAddress: (id, page, limit) => `user/userAddress/${id}?page=${page}&limit=${limit}`,
    epReferralDetails: (id) => `/user-referral-system/get-user-data/user-id/${id}`,
    orderDetails: (id) => `order/ongoing-orders/${id}`,
    userEcoPoints: (id) => `eco-points/${id}`,
    referredUsers: (page, limit, search = undefined) => {
        let url = `/user/referred/users?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    addUserUpi: (id) => `/razorpay?type=user&user_id=${id}`,
    deleteUserUpi: (id) => `/razorpay/${id}`
})

export const pickup = Object.freeze({
    orderDetails: (id) => `order/ongoing-orders/${id}`,
    assignPickExecEP: (id) => `order/update-status/${id}`,
    closeOrderEP: (id) => `update-status/${id}`,
    pickUpExec: 'delivery-executive',
    // pickUpExecOnlyActive: 'delivery-executive?status=true',
    pickUpExecutiveForEcoStoreAndPickUps: (values) => {
        let url = `delivery-executive/assign?type=${values}`
        return url
    },
    slotsList: (date, type = undefined, coordinates) => {
        let url = `time-slot-status?date=${date}`
        if (coordinates && Array.isArray(coordinates)) {
            url += `&lat=${coordinates[0]}&lng=${coordinates[1]}`
        }
        if (type) {
            url += `&type=${type}`
        }

        return url
    },
    // pickUpOrders: (date, id, status) => `order?date=${date}&slotId=${id}&status=${status}`,
    pickUpOrders: (
        page,
        limit,
        date = undefined,
        id = undefined,
        status,
        search,
        rateFilter,
        pickupPayment,
        B2CPickupFilterExe,
        groupId = undefined
    ) => {
        let url = `order?page=${page}&limit=${limit}&status=${status}`
        if (date && date?.length > 0) {
            url += typeof date === 'string' ? `&date=${date}` : `&startDate=${date[0]}&endDate=${date[1]}`
        }
        if (id) {
            url += `&slotId=${id}`
        }
        if (search) {
            url += `&search=${search}`
        }
        if (rateFilter) {
            url += `&rating=${rateFilter}`
        }
        if (pickupPayment) {
            url += `&payoutStatus=${pickupPayment}`
        }
        if (Array.isArray(B2CPickupFilterExe) && B2CPickupFilterExe?.length > 0) {
            url += `&executiveId=${B2CPickupFilterExe}`
        }
        if (groupId) {
            url += `&groupId=${groupId}`
        }
        return url
    },
    EP_DRIVERS_LIST: `driver?page=1&limit=1000&filter=active`,
    timeSlotStatus: 'time-slot-status',
    cancelOrderEP: (id) => `order/cancel-order/${id}`,
    epOrderMaterialsDetails: (id) => `order/order-material/${id}`,
    reopenOrder: (id) => `order/update-order/${id}/5`,
    ongoingOrders: (id) => `order/ongoing/status?id=${id}`,
    duplicateOrder: (id) => `delivery-executive/special-order/${id}`
})

export const ecoStore = Object.freeze({
    //Categories Endpoints

    epGetAllCategoryList: (limit, page, search = undefined) => {
        let url = `ecostore/admin?limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }

        return url
    },
    epAddCategory: 'ecostore/categories',
    editCategory: (id) => `ecostore/categories/${id}`,
    deleteCategory: (id) => `ecostore/categories/${id}/delete`,

    epAddProduct: 'ecostore/products',
    epEditProduct: (id) => `ecostore/products/${id}`,
    epEcoStoreProductStatuses: (id) => `ecostore/products/${id}`,
    epdeleteProduct: (id) => `ecostore/products/${id}/delete`,

    //Brands Endpoints

    epEditBrandDetailsByID: (id, page, limit, search = undefined, filter = undefined) => {
        let url = `ecostore/admin/brands/${id}/products?page=${page}&limit=${limit}`
        if (search) {
            url += `&name=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },

    epEditBrandHeaderDetailsByID: (id) => `ecostore/admin/brands/${id}`,
    epGetAllBrandList: (limit, page, search = undefined, filter = undefined) => {
        let url = `ecostore/admin/brands?limit=${limit}&page=${page}`
        if (search) {
            url += `&name=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    epGetAllBrandsForProduct: (type) => `store-name?type=${type}`,
    epGetAllCategoryForProduct: 'ecostore/admin/categories',

    epAddBrand: 'ecostore/brands',
    epEditBrand: (id) => `ecostore/brands/${id}`,
    epDeleteBrand: (id) => `ecostore/brands/${id}/delete`,
    epEcoStoreKPI: `ecostore/admin/ecostore/kpi`,

    EP_GET_ALL_PRODUCTS: (page, limit, search = undefined, filter = undefined) => {
        let url = `ecostore/admin/products?page=${page}&limit=${limit}`
        if (search) {
            url += `&name=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    }
})

export const rateCard = Object.freeze({
    categoryList: (pageSize, current, search = undefined, filter = undefined) => {
        let url = `recyclable-category?limit=${pageSize}&page=${current}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    categoryProductList: (id, pageSize, current, search = undefined, filter = undefined) => {
        let url = `recyclable/${id}?limit=${pageSize}&page=${current}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    categorySubProductList: (id) => `/recyclable-types/recyclableId/${id}`,
    addCategory: 'recyclable-category',
    addCProduct: 'recyclable',
    addSubProduct: 'recyclable-types',
    editCategory: (id) => `recyclable-category/${id}/`,
    editCategoryProduct: (id) => `recyclable/${id}/`,
    editCategorySubProduct: (id) => `/recyclable-types/${id}`,
    deleteCategory: (id) => `recyclable-category/${id}`,
    deleteCategoryProduct: (id) => `recyclable/${id}`
})

export const timeSlots = Object.freeze({
    slotList: (filter = undefined) => {
        let url = 'time-slot'
        if (filter) {
            url += `?filter=${filter}`
        }
        return url
    },
    activeSlotList: (status = 'active') => `time-slot?filter=${status}`,
    createTimeSlots: 'time-slot',
    epUpdateTimeSlots: (id) => `time-slot/${id}`,
    deleteTimeSlot: (id) => `time-slot/${id}`,
    slotListWithoutCoordinates: (date) => `time-slot-status/admin?date=${date}`
})

export const paymentModeEPS = Object.freeze({
    epGetAllModes: 'payment-mode',
    epAddMode: 'payment-mode',
    epEdit: 'payment-mode',
    epChangeStatus: 'payment-mode',
    deletePaymentMode: (id) => `payment-mode/${id}`
})

export const pickUpExecEPS = Object.freeze({
    //Pickup executive endpoints

    epGetAllPickExec: (page, limit, search = undefined, filter = undefined) => {
        let url = `delivery-executive?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    epAddPickExec: 'delivery-executive',
    epGetOrdersByPickUpBoyId: (id, page, limit) => `delivery-executive/${id}?page=${page}&limit=${limit}`,
    epEditExecutiveStatus: (id) => `delivery-executive/${id}`,
    epEditExecutiveEcoOrders: (id) => `delivery-executive/${id}`,
    epKPIExecutive: 'delivery-executive/kpi',
    epSingleExecutive: (id) => `delivery-executive/details/${id}`,
    epDeleteExeAPI: (id) => `delivery-executive/${id}`,
    epAddFundId: (id) => `/razorpay?type=executive&executive_id=${id}`
})

export const ecoFeeds = Object.freeze({
    //Eco feeds category

    epGetAllFeedsList: (limit, page, search = undefined) => {
        let url = `feed-category?type=admin&limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    epAddCategory: 'feed-category',
    epEditCategory: (id) => `feed-category/${id}`,
    deleteCategory: (id) => `feed-category/${id}`,

    //Eco feeds Posts

    epGetAllFeedsListById: (id) => `feed-category/${id}`,
    epGetAllFeedPostsListById: (id) => `feeds/${id}`,

    // epFeedsList: (id, page) => {
    //     let url = `feeds/${id}?page=${page}&limit=5`
    //     return url
    // },

    epFeedsList: (page, id) => {
        let url = `feeds/${id}?page=${page}&limit=9`
        return url
    },

    epGetFeedPostById: (id) => `feed-category/details/${id}`,
    epAddPosts: 'feeds',
    epUpdatePosts: (id) => `feeds/${id}`,
    epDeleteFeedPost: (id) => `feeds/${id}`,
    epGetSingleFeedById: (id) => `feeds/single/${id}`
})

export const ecoStoreOrders = Object.freeze({
    orderDetails: (id) => `order/${id}`,
    assignPickExecEP: (id) => `ecostore/orders/${id}/update`,
    closeOrderEP: (id) => `update-status/${id}`,
    pickUpExec: 'delivery-executive',
    pickUpExecOnlyActive: 'delivery-executive?status=true',
    slotsList: (date) => `time-slot-status?date=${date}`,
    epEcoStoreOrders: (status, page, limit, search = undefined) => {
        let url = `ecostore/orders?status=${status}&page=${page}&limit=${limit}`
        if (search) {
            url += `&name=${search}`
        }
        return url
    },
    epEcoStoreOrderDetailsById: (id) => `ecostore/orders/${id}`,
    timeSlotStatus: 'time-slot-status',
    cancelOrderEP: (id) => `ecostore/orders/${id}/update`
})

export const ecoImpacts = Object.freeze({
    epImpactsGetList: 'impact',
    epRecyclabeCategoryList: (pageSize, current, search = undefined) => {
        let url = `recyclable-category?limit=${pageSize}&page=${current}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    epRecyclabeProductList: (id, pageSize, current, search = undefined) => {
        let url = `recyclable/${id}?limit=${pageSize}&page=${current}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },

    epAddImpact: 'impact'
})

export const userIssues = Object.freeze({
    epUserIssues: (value, type, page, limit, search = undefined) => {
        let url = `customer-support?status=${value}&type=${type}&page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    epCloseUserQuery: (id) => `customer-support/${id}`,
    epCloseDriverQuery: (id) => `customer-support/${id}`,
    epUserBusinessIssues: (value, type, page, limit, search = undefined) => {
        let url = `customer-support/user_business?status=${value}&type=${type}&page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },

    /* KPI's*/
    epCustomerSUserKPI: `customer-support/user-issue-kpi`,
    epCustomerSBusinessKPI: `customer-support/userbussiness/issue/kpi`,
    epCustomerSKPIEXE: `customer-support/executive/issue/kpi`,
    epCustomerSKPIDriver: `customer-support/driver/issue/kpi`,
    epCustomerSKPIWarehouseManager: `customer-support/warehouse-manager/issue/kpi`,
    EP_ALL_OPEN_ISSUES_COUNT: 'customer-support/all/open-issue/kpi'
})

export const driverEPS = Object.freeze({
    epWarehouse: (limit, page, search = undefined) => {
        let url = `ware-house?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    epGetDriverAllOrder: (id, limit, page, search = undefined) => {
        let url = `driver/all-orders/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    epGetDriverOrders: (id) => `driver/order/${id}`,
    epGetDriverList: (page, limit, search = undefined, filter = undefined) => {
        let url = `driver?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    epAddDriver: `driver`,
    epEditDriver: (id) => `driver/${id}`,
    EP_DRIVER_DELETE: (id) => `driver/${id}`,
    epDriverKPI: `customer-support/driver-issue-kpi`
})

export const warehouse = Object.freeze({
    //Eco feeds category
    getAllWarehouses: (limit, page, search = undefined, filter = undefined) => {
        let url = `ware-house?type=admin&limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    createWarehouse: `ware-house`,
    GET_SINGLE_WAREHOUSE: (id) => `ware-house/${id}`,
    editWarehouse: (id) => `ware-house/${id}`,
    deleteWarehouse: (id) => `ware-house/${id}`,
    getAllInwards: (id, limit, page, filter = undefined, startDate = undefined, endDate = undefined) => {
        let url = `inward/${id}?limit=${limit}&page=${page}`
        if (filter) {
            url += `&filter=${filter}`
        }
        if (startDate && endDate) {
            url += `&startDate=${startDate}&endDate=${endDate}`
        }
        return url
    },
    getInwardMaterials: (id) => {
        let url = `inward/inward-materials/${id}`
        // let url = `ware-house/inward-materials/${id}&limit=${limit}&page=${page}`
        // if (search) {
        //     url += `&search=${search}`
        // }
        return url
    },
    getOutwardMaterials: (id) => {
        let url = `outward/by-id/${id}`
        // let url = `ware-house/inward-materials/${id}&limit=${limit}&page=${page}`
        // if (search) {
        //     url += `&search=${search}`
        // }
        return url
    },
    CREATE_OUTWARD_EP: () => {
        let url = `outward`
        return url
    },
    getAllOutwards: (
        id,
        limit,
        page,
        filter,
        searchOutward = undefined,
        filterStatus = undefined,
        startDate = undefined,
        endDate = undefined
    ) => {
        let url = `outward/outward-list/warehouse_id/${id}?limit=${limit}&page=${page}&filter=${filter}`
        if (searchOutward) {
            url += `&client=${searchOutward}`
        }
        if (filterStatus) {
            url += `&pending=${filterStatus}`
        }
        if (startDate && endDate) {
            url += `&startDate=${startDate}&endDate=${endDate}`
        }
        return url
    },
    getAllReceiver: () => {
        let url = `outword-receiver`
        //let url = `ware-house/inward/${id}&limit=${limit}&page=${page}`
        // if (search) {
        //     url += `&search=${search}`
        // }
        return url
    },
    getReceiverId: (id) => {
        let url = `outword-receiver/${id}`
        //let url = `ware-house/inward/${id}&limit=${limit}&page=${page}`
        // if (search) {
        //     url += `&search=${search}`
        // }
        return url
    },
    categoryList: (pageSize, current, search = undefined) => {
        let url = `recyclable-category?limit=${pageSize}&page=${current}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    categoryProductList: (id, pageSize, current, search = undefined) => {
        let url = `recyclable/${id}?limit=${pageSize}&page=${current}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    stockCategory: (id, page, limit, search = undefined) => {
        let url = `recyclable-category/get-categories-qty/warehouse/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    stockProducts: (warehouseId, catID) => {
        let url = `recyclable-category/get-products-qty/${catID}/warehouse/${warehouseId}`
        return url
    },
    ledgerEndPoint: (id, limit, page, search = undefined) => {
        let url = `outward/all-ledger/${id}?limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    ledgerSDetails: (warehouseID, id, type, limit, page) => {
        let url = `outward/ledger-details/warehouse_id/${warehouseID}/ledger_id/${id}/type?type=${type}&limit=${limit}&page=${page}`
        return url
    },
    dismantled: (warehouseId, page, limit, month = undefined, year = undefined) => {
        let url = `dismantle/${warehouseId}?page=${page}&limit=${limit}`
        if (month && year) {
            url += `&month=${month}&year=${year}`
        }
        return url
    },
    dismantledProducts: (id) => `dismantle/extracted-products/${id}`,
    getCredits: (id) => `credits/${id}`,
    addCredits: `credits`,
    deleteCredits: (id) => `credits/${id}`,
    editCredits: (id) => `credits/${id}`
})

export const warehouseManager = Object.freeze({
    //Eco warehouse category
    getAllWarehouseManager: (limit, page, search = undefined, filter = undefined) => {
        let url = `warehouse-manager?type=admin&limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    createWarehouseManager: `warehouse-manager`,
    deleteManager: (id) => `warehouse-manager/${id}`,
    editManager: (id) => `warehouse-manager/${id}`
})

export const ECO_COINS = Object.freeze({
    EP_GET_COINS: (current, limit, search) => {
        let url = `eco-points/configuration/all?page=${current}&limit=${limit}`
        if (search) {
            url += `&name=${search}`
        }
        return url
    },
    EP_CREATE_COINS: `eco-points/configuration`,
    EP_EDIT_COINS: (id) => {
        return `eco-points/configuration/${id}`
    },
    EP_DELETE_COINS: (id) => {
        return `eco-points/configuration/${id}`
    }
})
export const RECEIVER = Object.freeze({
    EP_GET_RECEIVER: (page, limit, search) => {
        let url = `outword-receiver?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    EP_CREATE_RECEIVER: `outword-receiver`,
    EP_EDIT_RECEIVER: (id) => {
        return `outword-receiver/${id}`
    },
    EP_Delete_RECEIVER: (id) => {
        return `outword-receiver/${id}`
    }
})

export const PICKUP_CANCEL_REASONS = Object.freeze({
    EP_CREATE_REASON: `cancellation-reason`,
    EP_GET_ALL_REASONS: (role) => `cancellation-reason/all?role=${role}`,
    EP_GET_REASONS: (role) => `cancellation-reason?role=${role}`,
    EP_GET_SINGLE_REASONS: (id) => `cancellation-reason/${id}`,
    EP_UPDATE_REASONS: (id) => `cancellation-reason/${id}`,
    EP_DELETE_REASONS: (id) => `cancellation-reason/${id}`
})
export const STORE_CANCEL_REASONS = Object.freeze({
    EP_CREATE_REASON: `store-cancellation-reason`,
    EP_GET_ALL_REASONS: (role) => `store-cancellation-reason/all?role=${role}`,
    EP_GET_REASONS: (role) => `store-cancellation-reason?role=${role}`,
    EP_GET_SINGLE_REASONS: (id) => `store-cancellation-reason/${id}`,
    EP_UPDATE_REASONS: (id) => `store-cancellation-reason/${id}`,
    EP_DELETE_REASONS: (id) => `store-cancellation-reason/${id}`
})

export const BUSINESS_TO_BUSINESS = Object.freeze({
    EP_GET_BUSINESSES: (status, limit, page, search = undefined) => {
        let url = `business/user?status=${status}&limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    EP_GET_SINGLE_BUSINESSES: (id) => `business/user/${id}`,
    EP_ONGOING_STATUS_BUSINESSES: (id) => `business/order/status/${id}`,
    EP_UPDATE_BUSINESSES: (id) => `business/user/${id}`,
    EP_PLACE_B2BORDER: () => `business/order`,
    EP_UPDATE_BUSINESS_ORDER: (id) => `business/order/${id}`,
    EP_PLACE_RECURRING_ORDER: () => `recurring-orders`,
    EP_UPDATE_RECURRING_ORDER: (id) => `recurring-orders/${id}`,
    EP_ADD_UPI_iD: (id) => `razorpay/b2b/${id}`,
    EP_DELETE_UPI_ID: (id) => `razorpay/${id}`,
    EP_GET_BUSINESS_PICKUPS: (id, page, limit) =>
        `business/order/getOrdersByBusinessId/${id}?page=${page}&limit${limit}`,
    EP_GET_SINGLE_BUSINESS_PICKUPS: (id) => `business/order/${id}/single-order`,
    EP_GET_PENDING_COUNT: 'business/user/status/kpi',
    EP_PENDING_B2B_MARKERS: `business/user/get-all/map-view`
})

export const BUSINESS_TO_BUSINESS_P_ORDERS = Object.freeze({
    BUSINESS_P_ORDERS: (
        page,
        limit,
        date = undefined,
        id = undefined,
        status,
        search = undefined,
        b2BPaymentFilter = undefined,
        B2BPickupFilterExe = undefined
    ) => {
        let url = `business/order/admin-business-orders?page=${page}&limit=${limit}&status=${status}`
        if (date && date?.length > 0) {
            url += typeof date == 'string' ? `&date=${date}` : `&startDate=${date[0]}&endDate=${date[1]}`
        }
        if (id) {
            url += `&slotId=${id}`
        }
        if (search) {
            url += `&search=${search}`
        }
        if (b2BPaymentFilter) {
            url += `&filter=${b2BPaymentFilter}`
        }
        if (Array.isArray(B2BPickupFilterExe) && B2BPickupFilterExe?.length > 0) {
            url += `&executiveId=${B2BPickupFilterExe}`
        }
        return url
    },
    EP_SINGLE_B2B_ORDER_DETAILS: (id) => `/business/order/${id}/single-order`,
    EP_UPDATE_B2B_ORDER: (id) => `business/order/${id}`,
    EP_SINGLE_B2B_ORDER_MATERIALS: (id) => `business/order/get-order-material/${id}`
})

export const outwardClient = Object.freeze({
    GET_OUTWARD_CLIENTS: (page, limit, search = undefined) => {
        let url = `/outward-client?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    UPDATE_OUTWARD_CLIENT: (id) => `/outward-client/${id}`,
    ADD_OUTWARD_CLIENT: `/outward-client`
})
export const googleMapsLibraries = ['places', 'geometry', 'drawing']
export const googleMapApi = Object.freeze({
    GET_PICKUP_EXECUTIVES: (date = undefined) => {
        let url = `delivery-executive/assign?type=order-map`
        if (date) {
            url += `&date=${date}`
        }
        return url
    },
    GET_MARKERS_API: (date, filter) => {
        let url = `/order/get-order-location`
        if (date && filter) {
            url += `?date=${date}&tsid=${filter}`
        } else if (date) {
            url += `?date=${date}`
        } else if (filter) {
            url += `?tsid=${filter}`
        }

        return url
    },
    GET_BUSINESS_MARKERS_API: (date, filter) => {
        let url = `/order/get-business-order-location`
        if (date && filter) {
            url += `?date=${date}&tsid=${filter}`
        } else if (date) {
            url += `?date=${date}`
        } else if (filter) {
            url += `?tsid=${filter}`
        }
        return url
    },
    GET_EXECUTIVE_BY_MARKER: (id) => `/order/get-onpin-executive-info/executive-id/${id}`,
    GET_ORDER_BY_MARKER: (id, type) => `/order/get-onpin-user-info/order-id/${id}?type=${type}`,
    GET_ALL_USERS_OR_BUSINESS: (dateValues) => `/analysis/order-geom?from=${dateValues.from}&to=${dateValues.to}`,
    GET_UNAVAILABLE_SERVICE: (month = undefined, year, uType = undefined) => {
        let url = `/analysis/unavailable-service?year=${year}`

        if (month) {
            url += `&month=${month}`
        }
        if (uType) {
            url += `&type=${uType}`
        }

        return url
    },
    GET_ALL_AVAILABLE_POLYGONS: `/map-polygon/find-all-individual`,
    CREATE_POLYGON: `/map-polygon/`,
    UPDATE_POLYGON: (id) => `/map-polygon/${id}`,
    GET_POLYGON_BY_ID: (id) => `/map-polygon/${id}`,
    DELETE_POLYGON: (id) => `/map-polygon/${id}`
})

export const referrals = Object.freeze({
    GET_REQUESTED: (page, limit) => `/user-referral-system/get-requested-referrals?page=${page}&limit=${limit}`,
    GET_COMPLETED: (page, limit) => `/user-referral-system/get-completed-referrals?page=${page}&limit=${limit}`
})

export const dashboard = Object.freeze({
    GET_DASHBOARD_KPI: `/analysis/KPIs`,
    GET_RECENT_PERFORMANCE_B2C: `/analysis/weekly-pickup-analysis?type=user`,
    GET_RECENT_PERFORMANCE_B2B: `/analysis/weekly-pickup-analysis?type=business`,
    GET_MONTHLY_ORDER_B2B: (year) => {
        let url = `/analysis/monthly-order-details-b2b`
        if (year) {
            url += `?year=${year}`
        }
        return url
    },
    GET_MONTHLY_ORDER_B2C: (year) => {
        let url = `/analysis/monthly-order-details-b2c`
        if (year) {
            url += `?year=${year}`
        }
        return url
    },
    GET_COLLECTION_B2C: (month, year, sort = undefined, sortBy = undefined) => {
        let url = `/analysis/collection-analysis?month=${month}&year=${year}&type=user`
        if (sort || sortBy) {
            url += `&sort=${sort}&by=${sortBy}`
        }
        return url
    },
    GET_COLLECTION_B2B: (month, year, sort = undefined, sortBy = undefined) => {
        let url = `/analysis/collection-analysis?month=${month}&year=${year}&type=business`
        if (sort || sortBy) {
            url += `&sort=${sort}&by=${sortBy}`
        }
        return url
    },
    GET_SLOT_ANALYSIS: (month, year) => {
        let url = `/analysis/slot-analysis`
        if (month && year) {
            url += `?month=${month}&year=${year}`
        } else if (month) {
            url += `?month=${month}`
        } else if (year) {
            url += `?year=${year}`
        }
        return url
    },
    GET_PROFIT_REPORTS: (year, warehouseId) => {
        let url = `/analysis/profit-reports-and-stock`
        if (year && warehouseId) {
            url += `?year=${year}&warehouseId=${warehouseId}`
        } else if (warehouseId) {
            url += `?warehouseId=${warehouseId}`
        } else if (year) {
            url += `?year=${year}`
        }
        return url
    },
    GET_TOP_VENDORS: (month, year, sort = undefined, sortBy = undefined) => {
        let url = `/analysis/vendor-analysis?month=${month}&year=${year}`
        if (sort || sortBy) {
            url += `&sort=${sort}&by=${sortBy}`
        }
        return url
    },
    GET_SECONDHAND_ANALYSIS: (year, sort = undefined, sortBy = undefined, warehouseId = undefined) => {
        let url = `/analysis/individual-sales-analysis?year=${year}`
        if (sort || sortBy) {
            url += `&sort=${sort}&by=${sortBy}`
        }
        if (warehouseId) {
            url += `&warehouseId=${warehouseId}`
        }
        return url
    },
    GET_MATERIAL_SOLD_BULK: (type, month, year, sort = undefined, sortBy = undefined, warehouseId = undefined) => {
        let url = `/analysis/top-material-analysis?type=${type}&month=${month}&year=${year}`
        if (sort || sortBy) {
            url += `&sort=${sort}&by=${sortBy}`
        }
        if (warehouseId) {
            url += `&warehouseId=${warehouseId}`
        }
        return url
    },
    GET_MONTHLY_BULK: (year, sort = undefined, sortBy = undefined, warehouseId = undefined) => {
        let url = `/analysis/monthly-bulk-analysis?year=${year}`
        if (sort || sortBy) {
            url += `&sort=${sort}&by=${sortBy}`
        }
        if (warehouseId) {
            url += `&warehouseId=${warehouseId}`
        }
        return url
    },
    GET_EXEC_REPORT: (type, startDate, endDate, search = undefined) => {
        let url = `/pickup-executive/monthly-report?orderType=${type}`
        if (startDate && endDate) {
            url += `&startDate=${startDate}&endDate=${endDate}`
        }
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    GET_MONTHLY_NEW_USERS_REPORTS: (month = undefined, year = undefined) => {
        let url = `/analysis/new-user-analysis`
        if (month && year) {
            url += `?month=${month}&year=${year}`
        }
        return url
    },
    GET_MONTHLY_CANCELLED_ORDERS: (type, year = undefined) => {
        let url = `/analysis/get-cancelled-orders?type=${type}`
        if (year) {
            url += `&year=${year}`
        }
        return url
    },
    GET_ALL_SURVEYS: `/analysis/survey`,
    GET_USER_SURVEY_RECORDS: (surveyId, month = undefined, year = undefined) => {
        let url = `/analysis/user-survey-record/${surveyId}`
        if (month && year) {
            url += `?month=${month}&year=${year}`
        }
        return url
    },
    GET_INWARD_IMPACT_REPORTS: (year = undefined, warehouseId = undefined) => {
        let url = `/analysis/actual-report`
        if (year && warehouseId) {
            url += `?year=${year}&warehouseId=${warehouseId}`
        } else if (year) {
            url += `?year=${year}`
        } else if (warehouseId) {
            url += `?warehouseId=${warehouseId}`
        }
        return url
    },
    GET_EXECUTIVE_EXP: (month = undefined, year = undefined, search = undefined) => {
        let url = `/analysis/pickup-expense`
        if (month && year) {
            url += `?month=${month}&year=${year}`
        }
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    GET_FESTIVE_OFFER_ANALYSIS: (search = undefined, startDate = undefined, endDate = undefined) => {
        let url = `/analysis/offers-analysis`
        if (search && startDate && endDate) {
            url += `?search=${search}&startDate=${startDate}&endDate=${endDate}`
        } else if (startDate && endDate) {
            url += `?startDate=${startDate}&endDate=${endDate}`
        } else if (search) {
            url += `?search=${search}`
        }
        return url
    },
    GET_CASHBACK_REFERAL: (year) => `/analysis/cashback-analysis?year=${year}`
})

export const pickup_expenses = Object.freeze({
    GET_ALL_PICKUP_EXP: (
        limit,
        page,
        search = undefined,
        filter = undefined,
        month = undefined,
        year = undefined,
        type = undefined,
        exFilter
    ) => {
        let url = `/pickup-expense?limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&status=${filter}`
        }
        if (month) {
            url += `&month=${month}`
        }
        if (year) {
            url += `&year=${year}`
        }
        if (type) {
            url += `&type=${type}`
        }
        if (exFilter) {
            url += `&filter=${exFilter}`
        }
        return url
    },
    GET_SINGLE_PICKUP_EXP: (id) => `/pickup-expense/${id}`,
    PATCH_PICKUP_EXP: (id) => `/pickup-expense/${id}`
})

export const ngo = Object.freeze({
    GET_ALL_NGO: (page, limit, search = undefined) => {
        let url = `/ngo/get-all-ngos?limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    GET_SINGLE_NGO: (id) => `/ngo/get-ngo-by-id/${id}`,
    CREATE_NGO: `/ngo`,
    UPDATE_NGO: (id) => `/ngo/${id}`,
    DELETE_NGO: (id) => `/ngo/${id}`,
    INCOMPLETE_NGO_PAYMENT: (page, limit) => {
        let url = `/ngo/get-payment-ngos?limit=${limit}&page=${page}`
        return url
    },
    PAID_NGO_PAYMENT: (page, limit, search = undefined) => {
        let url = `/ngo/get-payment-ngos?type=paid&limit=${limit}&page=${page}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    UPDATE_NGO_PAYMENT: (id) => `/ngo/complete-payment-ngo/${id}`,
    COMPLETE_NGO_PAYMENT: `/ngo/complete-payment-ngo`,
    NGO_DONATIONS: (id, page, limit, month, year) =>
        `ngo/get-donations-kpi/${id}?page=${page}&limit=${limit}&month=${month}&year=${year}`,
    ONE_NGO_USERS: (id, page, limit, search = undefined) => {
        let url = `/ngo/get-donated-users/ngo-id/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    ONE_NGO_PICKUPS: (id, page, limit, search = undefined) => {
        let url = `/ngo/get-donated-pickups/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    INCOMPLETE_DONATED_PICKUPS: (id, page, limit, search = undefined) => {
        let url = `/ngo/get-incomplete-donated-pickups/ngo-id/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    }
})

export const adminExp = Object.freeze({
    ADMIN_EXPENSES_GET: (page, limit, search = undefined, filter, year, type) => {
        let url = `/admin-expense/all?page=${page}&limit=${limit}&filter=${filter}&year=${year}`
        if (search) {
            url += `&search=${search}`
        }
        if (type) {
            url += `&type=${type}`
        }
        return url
    },
    ADD_ADMIN_EXPENSE: '/admin-expense',
    EDIT_ADMIN_EXPENSE: (id) => `/admin-expense/${id}`,
    SINGLE_ADMIN_EXPENSE: (id) => `/admin-expense/${id}`
})

export const pinCodeApis = Object.freeze({
    GET_LIST_PINCODES: (page, limit, search = undefined) => {
        let url = `/service-pincode?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    CREATE_PINCODE: `/service-pincode`,
    UPDATE_PINCODE: (id) => `/service-pincode/${id}`,
    DELETE_PINCODE: (id) => `/service-pincode/${id}`,
    SINGLE_PINCODE: (id) => `/service-pincode/${id}`,
    GET_ALL_SERVICABLE_PINCODES: `/service-pincode/map-pincodes`,
    GET_LIST_GROUPS: (page, limit, search = undefined) => {
        let url = `/service-pincode/groups/all?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    CREATE_GROUP: `/service-pincode/groups`,
    UPDATE_GROUP: (id) => `/service-pincode/groups/${id}`,
    DELETE_GROUP: (id) => `/service-pincode/groups/${id}`,
    SINGLE_GROUP: (id) => `/service-pincode/groups/${id}`
})

export const offersApis = Object.freeze({
    GET_ALL_FESTIVR_OFFERS: (page, limit, search = undefined, filter = undefined) => {
        let url = `/offers?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    CREATE_OFFER: `/offers`,
    UPDATE_OFFER: (id) => `/offers/${id}`
})
export const adminRolesOrUsers = Object.freeze({
    GET_ADMIN_ROLES: (page, limit, search = undefined) => {
        let url = `/admin-roles?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    ADD_ROLE: `/admin-roles`,
    UPDATE_ROLE: (id) => `/admin-roles/${id}`,
    DELETE_ROLE: (id) => `/admin-roles/${id}`,
    GET_ADMIN_USERS: (page, limit, search = undefined) => {
        let url = `/admin-auth/admins?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    ADD_USER: `/admin-auth`,
    UPDATE_USER: (id) => `/admin-auth/admins/${id}`,
    GET_USER_INFO: (id) => `/admin-auth?adminId=${id}`
})

export const executiveCommets = Object.freeze({
    GET_EXECUTIVE_COMMENTS: () => `/executive-comment`,
    UPDATE_EXECUTIVE_COMMENTS: (id) => `/executive-comment/${id}`,
    ADD_EXECUTIVE_COMMENTS: `/executive-comment`,
    ADD_ORDER_EXECUTIVE_COMMENTS: (id) => `/delivery-executive/comment-order/${id}`,
    UPDATE_ORDER_EXECUTIVE_COMMENTS: (id) => `/delivery-executive/comment-order/${id}`,
    GET_SINGLE_ORDER_COMMENT_SUMMARY: (id) => `/executive-comment/order-comments/${id}`
})

export const SIDEBAR_BADGE_COUNT = Object.freeze({
    GET_COUNTS: '/customer-support/get-badge/kpi'
})
