import React from 'react'
import { PropTypes } from 'prop-types'
import { Navigate } from 'react-router-dom'

RequireAuth.propTypes = {
    children: PropTypes.array,
    userToken: PropTypes.string
}

export default function RequireAuth({ children }) {
    const userToken = localStorage.getItem('myToken')
    if (!userToken) return <Navigate to="/auth/sign-in" />

    return children
}
