import React, { useContext } from 'react'
import SignInStyle from '../style/signin.module.scss'
import { Form, Button, Row, Col, Input, message } from 'antd'
import roundImage1 from '../../../assets/signin/Ellipse95.png'
import roundImage2 from '../../../assets/signin/Ellipse94.png'
import roundImage3 from '../../../assets/signin/Ellipse98.png'
import roundImage4 from '../../../assets/signin/Ellipse96.png'
import roundImage2Inner from '../../../assets/signin/Ellipse97.png'
import logoImage from '../../../assets/signin/EcocrewLogo@3x3.png'
import logoName from '../../../assets/signin/ecocrew.png'
import logoDesc from '../../../assets/signin/rewarding.png'
import { DownCircleOutlined } from '@ant-design/icons'
// import { Link } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { loginAPIHelper } from '../api-helper/login-helper'
import { MainContext } from '../../../App'

function SignIn() {
    const navigate = useNavigate()
    const { setAdminIsLoggedIn, setRolesArray } = useContext(MainContext)
    const { isLoading, mutateAsync } = useMutation(loginAPIHelper, {
        onError: (e) => {
            message.error(e.message)
        }
    })

    const onFinish = async (values) => {
        try {
            const data = await mutateAsync(values)
            localStorage.setItem('myToken', data.data.token)
            localStorage.setItem('adminType', data.data.admin?.adminType)
            localStorage.setItem('isLoggedIn', true)
            localStorage.setItem('adminEmail', data?.data?.admin?.email)
            setRolesArray(data?.data?.role ? data?.data?.role : [])
            message.success('Login Successfully')
            loginUser(true)
            setAdminIsLoggedIn(true)
        } catch (error) {
            message.error(error.response.data.message)
            throw new Error(error)
        }
    }

    const loginUser = (value = false) => {
        if (value) {
            console.log('user token Found in auth sign in ')
            navigate('/')
        }
    }

    const scrollTo = () => {
        window.scrollTo({
            top: 600,
            behavior: 'smooth'
        })
    }

    return (
        <>
            <div className={SignInStyle.mainContainer}>
                <Row className={SignInStyle.content}>
                    <Col className={SignInStyle.formContent} lg={12} md={24} sm={24} xs={24}>
                        <div className={SignInStyle.SignInForm}>
                            <h2 className={SignInStyle.welcome}>WELCOME!</h2>
                            <h1 className={SignInStyle.signInText}>Sign in</h1>
                            <Form
                                className={SignInStyle.form}
                                name="basic"
                                onFinish={onFinish}
                                initialValues={{ remember: true }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                autoComplete="off">
                                <Form.Item
                                    label="Please enter your username"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Please enter your username!' },
                                        {
                                            pattern: new RegExp('[a-zA-Z]+'),
                                            message: 'please enter valid username'
                                        }
                                    ]}>
                                    <Input className={SignInStyle.inputContainer} />
                                </Form.Item>

                                <Form.Item
                                    label="Please enter your password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please enter your password!' }]}>
                                    <Input.Password className={SignInStyle.inputContainer} />
                                </Form.Item>
                                <Link to="/auth/forgot-password">
                                    <Button className={SignInStyle.forgotLink} type="link">
                                        <u>Forgot password?</u>
                                    </Button>
                                </Link>
                                <br />
                                <Form.Item>
                                    <Button
                                        loading={isLoading}
                                        type="default"
                                        htmlType="submit"
                                        className={SignInStyle.submitBtn}>
                                        Sign in
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col className={SignInStyle.mainPageChild2} lg={12} md={24} sm={24} xs={24}>
                        <div className={SignInStyle.imageContent}>
                            <div className={SignInStyle.image1}>
                                <img src={roundImage1} alt="image1" />
                            </div>
                            <div className={SignInStyle.image2}>
                                <img className={SignInStyle.image2Inner} src={roundImage2Inner} alt="image2inner" />
                                <img src={roundImage2} alt="image2" />
                            </div>
                            <div className={SignInStyle.logoContainer}>
                                <div className={SignInStyle.logoImage}>
                                    <img className={SignInStyle.imgLogo} src={logoImage} alt="logo" />
                                    <img className={SignInStyle.logoName} src={logoName} alt="logoName" />
                                    <img className={SignInStyle.logoDesc} src={logoDesc} alt="logoDesc" />
                                </div>
                            </div>
                            <div className={SignInStyle.image3}>
                                <img src={roundImage3} alt="" />
                            </div>
                            <div className={SignInStyle.image4}>
                                <img className={SignInStyle.image4Inner} src={roundImage2Inner} alt="image4inner" />
                                <img src={roundImage4} alt="" />
                            </div>
                            <div className={SignInStyle.angleCircle}>
                                <div className={SignInStyle.circle}>
                                    <Button onClick={() => scrollTo()} type="link" className={SignInStyle.btn}>
                                        <DownCircleOutlined />
                                    </Button>
                                </div>
                                <div className={SignInStyle.text}>
                                    <h4>Scroll to login</h4>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SignIn
