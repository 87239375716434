import React, { Suspense } from 'react'
import SpinnerComp from '../components/spinnerComp'
import { PropTypes } from 'prop-types'

SuspenseWrapper.propTypes = {
    component: PropTypes.element
}

export default function SuspenseWrapper({ component }) {
    function Spinner() {
        return (
            <div className="centerSpinnerForLazyComponents">
                <SpinnerComp />
            </div>
        )
    }
    return <Suspense fallback={<Spinner />}>{component}</Suspense>
}
