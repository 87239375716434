import axios from 'axios'
import { message } from 'antd'

export const baseURL = process.env.REACT_APP_BACKEND_URL

const client = axios.create({ baseURL: baseURL, withCredentials: true })
export const request = ({ ...options }) => {
    const userToken = localStorage.getItem('myToken')
    client.defaults.headers.common = {
        // Authorization: userToken
        Authorization: `Bearer  ${userToken}`
    }

    const onSuccess = (response) => {
        if (response?.headers?.new_auth_token) {
            let refreshToken = response?.headers?.new_auth_token
            localStorage.setItem('myToken', refreshToken)
        }
        return response.data
    }

    const onError = (error) => {
        if (error?.response?.data?.statusCode == 401) {
            message.error('Session token is expired')
            localStorage.setItem('myToken', '')
            window.location.replace('/auth/sign-in')
        } else if (error.code === 'ERR_NETWORK') {
            message.error('No internet connection')
        } else if (error.response.data.statusCode == 403) {
            let newError = JSON.parse(error?.request?.response)
            throw newError
        } else if (error.response.data.statusCode == 500) {
            let newError = JSON.parse(error?.request?.response)

            throw newError
        } else if (error?.response?.status == 400) {
            let newErr = error?.response?.data?.message ? error?.response?.data : 'Something went wrong!'
            throw newErr
        }
        console.log(error, '=====')
        throw error
    }

    return client(options).then(onSuccess).catch(onError)
}
