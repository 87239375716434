// importing react
import React, { useState, createContext, useEffect } from 'react'

// for routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

//for devtoolextension
import { ReactQueryDevtools } from 'react-query/devtools'
// imports from utils
import ProtectedRoutes from './utils/ProtectedRoutes'
import RequireAuth from './utils/RequireAuth'

// importing custom components
import Auth from './components/auth'
import ForgotPassword from './components/auth/components/ForgotPassword'
import SpinnerComp from './components/spinnerComp'
import PreviewModal from './components/commonComps/PreviewModal'

// firebase  ==========
import { useMutation } from 'react-query'
import firebase from 'firebase/compat/app'
import { fetchToken, onMessageListener } from './firebase'
import { openNotification } from './utils/notification'
import { CHANGE_ADMIN_PASS, USE_GET_ADMIN_AUTH } from './components/cmpNavBar/api-helper/api-helper'
import { message } from 'antd'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseconfig'

// ant css import

import './App.less'
import { useBeforeRender } from './hooks/useBeforeRender'
import GlobalStates from './components/globalStates'

export const MainContext = createContext()

function App() {
    const userToken = localStorage.getItem('myToken')
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const [adminIsLoggedIn, setAdminIsLoggedIn] = useState(isLoggedIn ? JSON.parse(isLoggedIn) : false)
    const [rolesArray, setRolesArray] = useState([])
    const [sidebarWarehose, setSidebarWarehouse] = useState([])
    const [adminUsersTab, setAdminUsersTab] = useState('roles')
    const { isLoading } = USE_GET_ADMIN_AUTH(setRolesArray, setSidebarWarehouse, userToken)

    /**
     *
     * firebase START--->>>
     */
    initializeApp(firebaseConfig)
    const [tokenData, setTokenData] = useState({ tokenFound: false, token: null })
    const [gotNotification, setGotNotification] = useState(false)
    const [messageNotification, setMessageNotification] = useState({ title: null, body: null })
    const UPDATE_ADMIN_MUTATION = useMutation(CHANGE_ADMIN_PASS)
    useEffect(() => {
        if (!firebase.apps.length && userToken) {
            fetchToken(setTokenData)
        }
    }, [userToken, adminIsLoggedIn])

    const sendTokenDetails = async (token) => {
        try {
            await UPDATE_ADMIN_MUTATION.mutateAsync({
                fcmToken: token
            })
        } catch (error) {
            message.error(error.message)
        }
    }
    onMessageListener()
        .then((payload) => {
            setGotNotification(true)
            setMessageNotification({ title: payload.notification.title, body: payload.notification.body })
        })
        .catch((err) => console.log('failed: ', err))

    useEffect(() => {
        if (!tokenData.tokenFound) return
        else if (tokenData.tokenFound) {
            sendTokenDetails(tokenData.token)
        }
    }, [tokenData])

    useEffect(() => {
        if (gotNotification) {
            if (!messageNotification.title && !messageNotification.body) return
            else if (messageNotification.title && messageNotification.body) {
                openNotification(messageNotification, setGotNotification)
            }
        }
    }, [messageNotification])

    /**
     *
     * firebase End--->>>
     */

    useBeforeRender(() => {
        window.addEventListener('error', (e) => {
            if (e) {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
                if (resizeObserverErr) resizeObserverErr.className = 'display-none'
                if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'display-none'
            }
        })
    }, [])

    if (isLoading) {
        return (
            <div className="centerSpinnerForLazyComponents">
                <SpinnerComp />
            </div>
        )
    }

    return (
        <MainContext.Provider
            value={{
                adminIsLoggedIn,
                setAdminIsLoggedIn,
                rolesArray,
                setRolesArray,
                sidebarWarehose,
                setSidebarWarehouse,
                adminUsersTab,
                setAdminUsersTab
            }}>
            <GlobalStates>
                <Router>
                    <Routes>
                        <Route path="/auth/sign-in" element={<Auth />} />
                        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                        <Route
                            path="/*"
                            element={
                                <RequireAuth userToken={userToken}>
                                    <ProtectedRoutes />
                                    <PreviewModal />
                                </RequireAuth>
                            }
                        />
                    </Routes>
                </Router>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </GlobalStates>
        </MainContext.Provider>
    )
}
export default App
