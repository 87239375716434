export const routes = {
    dashboard: {
        path: '/dashboard',
        name: 'Dashboard',
        innerRoutes: {
            all_details: {
                path: '/dashboard',
                name: 'All details',
                children: null
            },
            dashboard_map: {
                path: '/dashboard/map',
                name: 'Dashboard Map',
                children: null
            },
            dashboard_service_map: {
                path: '/dashboard/service-map',
                name: 'Dashboard Service Map',
                children: null
            }
        }
    },
    admin_users: {
        path: '/admin-users',
        name: 'Admin users',
        children: null
    },
    users: {
        path: 'Users',
        name: 'Users',
        children: {
            users_list: {
                path: '/users/list-users',
                name: 'User list',
                children: null,
                innerRoutes: {
                    view_user_details: {
                        path: '/users/view-user-details/:user_id',
                        name: 'View user list',
                        children: null
                    }
                }
            }
        }
    },
    businesses: {
        path: 'Businesses',
        name: 'Businesses',
        children: {
            active: {
                path: '/businesses/list-active',
                name: 'Active businesses',
                children: null
            },
            pending: {
                path: '/businesses/list-pending',
                name: 'Pending businesses',
                children: null
            },
            rejected: {
                path: '/businesses/list-rejected',
                name: 'Rejected businesses',
                children: null
            }
        },
        innerRoutes: {
            pending_map_view: {
                path: '/businesses/list-pending/pending-map-view',
                name: 'Pending map view',
                children: null
            },
            view_business: {
                path: '/businesses/business-profile/:b2b_profile_id',
                name: 'View business',
                children: null
            }
        }
    },
    slots: {
        path: '/time-slots/list-time-slots',
        name: 'Slots',
        children: null
    },
    feeds: {
        path: '/eco-feeds/feeds-category',
        name: 'Feeds',
        children: null,
        innerRoutes: {
            post: {
                path: '/eco-feeds/feed-posts/:feedCatId',
                name: 'Feed post',
                children: null
            }
        }
    },
    impact: {
        path: '/eco-impacts/impacts-category',
        name: 'Impacts',
        children: null
    },
    pickup_orders: {
        path: 'Pickup orders',
        name: 'Pickup orders',
        innerRoutes: {
            occ_info_details: {
                path: '/pickup-schedule/occ-info-details/:order_id/:selected_Slot',
                name: 'OCC Info Details',
                children: null
            }
        },
        children: {
            pickup_ongoing: {
                path: '/pickup-schedule/list-pickup-schedule/Ongoing',
                name: 'Pickup ongoing',
                children: null
            },
            pickup_completed: {
                path: '/pickup-schedule/list-pickup-schedule/Completed',
                name: 'Pickup completed',
                children: null
            },
            pickup_cancelled: {
                path: '/pickup-schedule/list-pickup-schedule/Cancelled',
                name: 'Pickup cancelled',
                children: null
            }
        }
    },
    b2b_pickup_orders: {
        path: 'B2B pickups',
        name: 'B2B pickups',
        innerRoutes: {
            business_order_details: {
                path: '/business-pickup-schedule/business-order-details/:b2b_order_id/:b2b_selected_Slot',
                name: 'Business Order Details',
                children: null
            }
        },
        children: {
            business_ongoing: {
                path: '/business-pickup-schedule/b2b-list-pickup-schedule/Ongoing',
                name: 'B2B ongoing',
                children: null
            },
            business_completed: {
                path: '/business-pickup-schedule/b2b-list-pickup-schedule/Completed',
                name: 'B2B completed',
                children: null
            },
            business_cancelled: {
                path: '/business-pickup-schedule/b2b-list-pickup-schedule/Cancelled',
                name: 'B2B cancelled',
                children: null
            }
        }
    },
    eco_store_orders: {
        path: 'Eco store orders',
        name: 'Eco store orders',
        innerRoutes: {
            eco_order_details: {
                path: '/eco-store-orders/eco-order-details/:ecoOrderId',
                name: 'Eco Store Orders Details',
                children: null
            }
        },
        children: {
            user_order_received: {
                path: '/eco-store-orders/list-orders/user_order_received',
                name: 'Current orders',
                children: null
            },
            vendor_order_placed: {
                path: '/eco-store-orders/list-orders/vendor_order_placed',
                name: 'Ordered by Vendors',
                children: null
            },
            items_received: {
                path: '/eco-store-orders/list-orders/items_received',
                name: 'Order in warehouse',
                children: null
            },
            executive_assigned: {
                path: '/eco-store-orders/list-orders/executive_assigned',
                name: 'Assigned to delivery',
                children: null
            },
            executive_out_for_delivery: {
                path: '/eco-store-orders/list-orders/executive_out_for_delivery',
                name: 'Out for delivery',
                children: null
            },
            order_delivered: {
                path: '/eco-store-orders/list-orders/order_delivered',
                name: 'Completed orders',
                children: null
            },
            cancelled: {
                path: '/eco-store-orders/list-orders/cancelled',
                name: 'Cancelled orders',
                children: null
            },
            replacement_orders: {
                path: 'Replacement orders',
                name: 'Replacement orders',
                children: {
                    replace_requested: {
                        path: '/eco-store-orders/list-orders/replacement-orders/replace_requested',
                        name: 'Replacement requested',
                        children: null
                    },
                    replaced: {
                        path: '/eco-store-orders/list-orders/replacement-orders/replaced',
                        name: 'Replacement completed',
                        children: null
                    }
                }
            }
        }
    },
    customer_issues: {
        path: 'Customer issues',
        name: 'Customer issues',
        children: {
            query_users: {
                path: '/query-issues/user-query',
                name: 'Customer issues users',
                children: null
            },
            query_business: {
                path: '/query-issues/business-query',
                name: 'Customer issues business',
                children: null
            },
            query_pickups: {
                path: '/query-issues/pickup-query',
                name: 'Customer issues Pickups / Delivery agents',
                children: null
            },
            query_warehouse_manager: {
                path: '/query-issues/warehouse-query',
                name: 'Customer issues warehouse manager',
                children: null
            },
            query_driver: {
                path: '/query-issues/driver-query',
                name: 'Customer issues driver',
                children: null
            }
        }
    },
    warehouse: {
        path: 'Warehouse',
        name: 'Warehouse',
        children: {}
    },
    master_directory: {
        path: 'Master directory',
        name: 'Master directory',
        children: {
            cancellation_reason: {
                path: 'Cancellation reason',
                name: 'Cancellation reason',
                children: {
                    c_pickeup: {
                        path: '/master-directory/cancellation-reason/c-pickeup',
                        name: 'Cancel Pickup orders',
                        children: null
                    },
                    c_eco_store: {
                        path: '/master-directory/cancellation-reason/c-eco-store',
                        name: 'Cancel eco store',
                        children: null
                    }
                }
            },
            pickup_executive: {
                path: '/master-directory/pickup-executives/list-pickup-executives',
                name: 'Pickup executive',
                children: null,
                innerRoutes: {
                    view_single_executive_details: {
                        path: '/pickup-executives/view-pickup-executive-details/:pickUpExecId',
                        name: 'View Single Executive Details',
                        children: null
                    },
                    edit_single_executive_details: {
                        path: '/pickup-executives/edit-pickExec/:editIdPickup',
                        name: 'Edit Single Executive Details',
                        children: null
                    },
                    add_executive_details: {
                        path: '/pickup-executives/add-pickExec',
                        name: 'Add Executive Details',
                        children: null
                    }
                }
            },
            executive_comments: {
                path: '/master-directory/executive-comments',
                name: 'Executive comments',
                children: null
            },
            driver: {
                path: '/master-directory/eco-driver/driver-list',
                name: 'Driver',
                children: null,
                innerRoutes: {
                    view_single_driver_details: {
                        path: '/eco-driver/driver-loads/:driverId',
                        name: 'View Single Driver Details',
                        children: null,
                        innerRoutes: {
                            view_executive_order_history: {
                                path: 'eco-driver/driver-OCC',
                                name: 'View Executive Order History',
                                children: null
                            }
                        }
                    },
                    add_driver_details: {
                        path: 'eco-driver/driver-add',
                        name: 'Add Driver Details',
                        children: null
                    },
                    edit_driver_details: {
                        path: '/eco-driver/driver-edit/:driverIdForEdit',
                        name: 'Edit Driver Details',
                        children: null
                    }
                }
            },
            warehouse_manager: {
                path: '/master-directory/warehouse-and-manager/warehouse-list',
                name: 'Warehouse manager',
                children: null,
                innerRoutes: {
                    add_warehouse: {
                        path: '/warehouse-and-manager/warehouse-add-edit',
                        name: 'Add Warehouse',
                        children: null
                    },
                    view_warehouse_details: {
                        path: '/warehouse-and-manager/warehouse-details/:warehouseId',
                        name: 'View WareHouse Details',
                        children: null,
                        innerRoutes: {
                            warehouse_material_details: {
                                path: '/warehouse-and-manager/material-details/:warehouseOrderId',
                                name: 'Warehouse Material Details',
                                children: null
                            }
                        }
                    },
                    edit_warehouse_details: {
                        path: '/warehouse-and-manager/warehouse-add-edit/:warehouseEditid',
                        name: 'Edit Warehouse Details',
                        children: null
                    },
                    edit_manager_details: {
                        path: '/warehouse-and-manager/manager-add-edit/:managerEditid',
                        name: 'Edit Manager Details',
                        children: null
                    },
                    add_manager_details: {
                        path: '/warehouse-and-manager/manager-add-edit',
                        name: 'Add Manager Details',
                        children: null
                    },
                    create_outward: {
                        path: '/warehouse-and-manager/warehouse-outward/outward-create',
                        name: 'Create outward',
                        children: null
                    },
                    driver_received_materials: {
                        path: '/warehouse-and-manager/driver-receiver-material-details/:warehouseOrderId',
                        name: 'Driver recieved materials',
                        children: null
                    }
                }
            },
            eco_store: {
                path: '/master-directory/eco-store/list-eco-stores',
                name: 'Eco store',
                children: null,
                innerRoutes: {
                    brand_view: {
                        path: '/eco-store/list-eco-products-brand/:ecostore_brand_id',
                        name: 'View eco store brand',
                        children: null
                    },
                    brand_add: {
                        path: '/eco-store/add-edit-brand',
                        name: 'Add eco store brand',
                        children: null
                    },
                    brand_edit: {
                        path: '/eco-store/add-edit-brand',
                        name: 'Edit eco store brand',
                        children: null
                    }
                }
            },
            rate_card: {
                path: '/master-directory/rate-card/list-rate-card',
                name: 'Rate card',
                children: null,
                innerRoutes: {
                    view_material: {
                        path: '/rate-card/list-rate-card-materials/:product_id',
                        name: 'View rate card material',
                        children: null,
                        innerRoutes: {
                            view_sub_product: {
                                path: '/rate-card/list-rate-card-sub-products/:sub_products_id',
                                name: 'View rate card sub products',
                                children: null
                            }
                        }
                    }
                }
            },
            b2b_rate_card: {
                path: '/master-directory/b2b-rate-card/b2b-list-rate-card',
                name: 'B2B rate card',
                children: null,
                innerRoutes: {
                    view_b2b_material: {
                        path: '/b2b-rate-card/list-rate-card-materials/:b2b_product_id',
                        name: 'View b2b rate card material',
                        children: null,
                        innerRoutes: {
                            view_b2b_sub_product: {
                                path: '/b2b-rate-card/list-rate-card-sub-products/:b2b_sub_products_id',
                                name: 'View b2b rate card sub products',
                                children: null
                            }
                        }
                    }
                }
            },
            payment_modes: {
                path: '/master-directory/payment-modes/list-payment-modes',
                name: 'Payment modes',
                children: null
            },
            NGOs: {
                path: '/master-directory/NGOs',
                name: 'NGOs',
                children: null,
                innerRoutes: {
                    view_NGO: {
                        path: '/view-NGO/:ngo_id',
                        name: 'View NGO',
                        children: null
                    },
                    edit_NGO: {
                        path: '/update-NGO/:ngo_update',
                        name: 'Update NGO',
                        children: null
                    },
                    add_NGO: {
                        path: '/create-NGO',
                        name: 'Create NGO',
                        children: null
                    }
                }
            },
            configuration: {
                path: '/master-directory/configuration',
                name: 'Configuration',
                children: null
            },
            outward_client: {
                path: '/master-directory/outward-client',
                name: 'Outward client',
                children: null
            },
            groups_pincodes: {
                path: '/master-directory/groups-pincodes',
                name: 'Groups / Pincodes',
                children: null,
                innerRoutes: {
                    pincode_available: {
                        path: '/pincodes-available-map',
                        name: 'Pincodes available',
                        children: null
                    },
                    polygon_available: {
                        path: '/polygon-available-map',
                        name: 'Polygon available',
                        children: null
                    }
                }
            },

            festive_offers: {
                path: '/master-directory/festive-offers',
                name: 'Festive offers',
                children: null
            }
        }
    },
    settlements: {
        path: 'Settlements',
        name: 'Settlements',
        children: {
            pickup_expenses: {
                path: '/settlements/list-pickup-expenses',
                name: 'Pickup expenses',
                children: null,
                innerRoutes: {
                    view_expense: {
                        path: '/pickup-expenses/view-expenses/:pExpenseId',
                        name: 'View pickup expenses',
                        children: null
                    }
                }
            },
            admin_expenses: {
                path: '/settlements/admin-expenses',
                name: 'Admin expenses',
                children: null
                // innerRoutes: {
                //     view_admin_exp: {
                //         path: '/admin-expense/:admin_expId',
                //         name: 'View admin expenses',
                //         children: null
                //     }
                // }
            },
            NGO_payments: {
                path: '/settlements/NGO-payments',
                name: 'NGO payments',
                children: null,
                innerRoutes: {
                    view_pickup_pending: {
                        path: '/view-ngo-pickups/pending/:view_ngo_id',
                        name: 'View NGO pickup pending',
                        children: null
                    },
                    view_pickup_complete: {
                        path: '/view-ngo-pickups/completed/:view_ngo_id',
                        name: 'View NGO pickup complete',
                        children: null
                    }
                }
            },
            referral_list: {
                path: '/settlements/referral-list',
                name: 'Referrals',
                children: null
            }
        }
    }
}
