//AXIOS
import { useQuery } from 'react-query'
import { request } from '../../../utils/axios'
import { SIDEBAR_BADGE_COUNT } from '../../../utils/eps'

//Get admin details
export const GET_ADMIN_DETAILS_API = () => {
    return request({ url: 'admin-auth', method: 'get' })
}

export const GET_SIDEBAR_BADGE_COUNT = () => {
    return request({ url: SIDEBAR_BADGE_COUNT.GET_COUNTS, method: 'get' })
}
//Change password
export const CHANGE_ADMIN_PASS = (values) => {
    return request({ url: 'admin-auth', method: 'put', data: { ...values } })
}

// USE_GET_ADMIN_AUTH =>>>>>>>>>>>>>>>>
export const USE_GET_ADMIN_AUTH = (setRolesArray, setSidebarWarehouse, userToken) =>
    useQuery(['GET_ADMIN_DETAILS_API'], () => GET_ADMIN_DETAILS_API(), {
        keepPreviousData: true,
        enabled: !!userToken,
        onSuccess(data) {
            setRolesArray(data?.role ? data?.role : [])
            setSidebarWarehouse(data?.warehouses ? data?.warehouses : [])
        }
    })
