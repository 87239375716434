import React, { useContext } from 'react'
import { Modal } from 'antd'
import { globalStates } from '../../globalStates'
import ErrorBoundary from '../../../utils/ErrorBoundary'

export default function index() {
    const { previewOpen, setPreviewOpen, previewTitle, setPreviewTitle, previewImage, setPreviewImage } =
        useContext(globalStates)

    const handleCancel = () => {
        setPreviewTitle('')
        setPreviewImage('')
        setPreviewOpen(false)
    }
    return (
        <Modal open={previewOpen} maskClosable={false} title={previewTitle} footer={null} onCancel={handleCancel}>
            <ErrorBoundary>
                <img
                    alt="example"
                    style={{
                        width: '100%'
                    }}
                    src={previewImage}
                />
            </ErrorBoundary>
        </Modal>
    )
}
